var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex flex-row"},[_c('div',[_c('h1',{staticClass:"text-xl font-medium font-headers mb-3 font-bold text-grey-dark-2"},[_c('span',{staticClass:"text-grey-medium-2 font-normal"},[_vm._v(_vm._s(("Companies / " + (_vm.currentCompany.name) + " / Subscription and Invites /")))]),_vm._v(" Subscription Details ")])]),(_vm.subscriptionInfo)?_c('div',{staticClass:"ml-auto"},[(_vm.subscriptionInfo.subscriptionStatus === 0)?_c('div',{staticClass:"flex flex-row space-x-2"},[_c('Button',{attrs:{"text":"Activate","size":"medium","disabled":!_vm.hasSelectedProducts},nativeOn:{"click":function($event){return _vm.updateSubscription({
              organizationId: _vm.currentCompany.id,
              action: 1,
            })}}})],1):_vm._e(),(_vm.subscriptionInfo.subscriptionStatus === 1)?_c('div',{staticClass:"flex flex-row space-x-2"},[_c('Button',{attrs:{"text":"Deactivate","size":"medium","type":"danger"},nativeOn:{"click":function($event){return _vm.updateSubscriptionStatus({
              organizationId: _vm.currentCompany.id,
              action: 2,
            })}}}),_c('Button',{attrs:{"text":"Suspend","size":"medium","type":"primary-white"},nativeOn:{"click":function($event){return _vm.updateSubscriptionStatus({
              organizationId: _vm.currentCompany.id,
              action: 3,
            })}}}),_c('Button',{attrs:{"text":"Update","size":"medium","type":"primary","disabled":!_vm.hasSelectedProducts || !_vm.hasChanges},nativeOn:{"click":function($event){return _vm.updateSubscription({
              organizationId: _vm.currentCompany.id,
              action: 4,
            })}}})],1):_vm._e(),(_vm.subscriptionInfo.subscriptionStatus === 3)?_c('div',{staticClass:"flex flex-row space-x-2"},[_c('Button',{attrs:{"text":"Deactivate","size":"medium","type":"danger"},nativeOn:{"click":function($event){return _vm.updateSubscriptionStatus({
              organizationId: _vm.currentCompany.id,
              action: 2,
            })}}}),_c('Button',{attrs:{"text":"Resume","size":"medium","type":"primary","disabled":!_vm.hasSelectedProducts},nativeOn:{"click":function($event){return _vm.updateSubscription({
              organizationId: _vm.currentCompany.id,
              action: 1,
            })}}})],1):_vm._e(),(
          _vm.subscriptionInfo.subscriptionStatus === 4 ||
            _vm.subscriptionInfo.subscriptionStatus === 2
        )?_c('div',{staticClass:"flex flex-row space-x-2"},[_c('Button',{attrs:{"text":"Renew","size":"medium","type":"primary","disabled":!_vm.hasSelectedProducts},nativeOn:{"click":function($event){return _vm.updateSubscription({
              organizationId: _vm.currentCompany.id,
              action: 1,
            })}}})],1):_vm._e()]):_vm._e()]),_c('div',[_c('SubscriptionDetailsForm')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }