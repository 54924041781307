<template>
  <div class="flex flex-col">
    <div class="flex items-center">
      <Select
        label="Timezone"
        v-model="timezone"
        :options="timezoneOptions"
        :preselectFirst="false"
        :onSelect="(value) => (timezone = value)"
        class="mr-2"
        disabled
      />
      <DatePicker
        v-model="start"
        mode="dateTime"
        timezone="UTC"
        :key="start + Math.random()"
        :is-required="true"
        :max-date="end"
        data-testid="start-date-select"
        :model-config="{
          type: 'string',
          mask: 'YYYY-MM-DDTHH:mm:ss',
          timeAdjust: '06:00:00',
        }"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <div>
            <p class="text-sm-2 text-grey-light font-medium mb-0.4 relative">
              Start Date
            </p>
            <div class="flex">
              <input
                :value="inputValue"
                v-on="inputEvents"
                class="w-full outline-none placeholder-grey-light-2 font-medium text-md text-grey-dark-1 px-2 py-1 border-grey-light-1 border rounded focus:border-blue-dark-1 disabled:text-grey-light disabled:placeholder-grey-light-1 disabled:border-grey-light-1 disabled:bg-white"
              />
              <img src="@/assets/img/icons/date.svg" class="relative right-4" />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="absolute bottom-0 inset-x-0 h-68 opacity-60 bg-white rounded-b-lg" />
        </template>
      </DatePicker>
      <DatePicker
        v-model="end"
        mode="dateTime"
        timezone="UTC"
        :key="end + Math.random()"
        :is-required="true"
        :min-date="start"
        data-testid="end-date-select"
        :model-config="{
          type: 'string',
          mask: 'YYYY-MM-DDTHH:mm:ss',
          timeAdjust: '23:59:00',
        }"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <div>
            <p class="text-sm-2 text-grey-light font-medium mb-0.4 relative">
              End Date
            </p>
            <div class="flex">
              <input
                :value="inputValue"
                v-on="inputEvents"
                class="w-full outline-none placeholder-grey-light-2 font-medium text-md text-grey-dark-1 px-2 py-1 border-grey-light-1 border rounded focus:border-blue-dark-1 disabled:text-grey-light disabled:placeholder-grey-light-1 disabled:border-grey-light-1 disabled:bg-white"
              /><img src="@/assets/img/icons/date.svg" class="relative right-4" />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="absolute bottom-0 inset-x-0 h-68 opacity-60 bg-white rounded-b-lg" />
        </template>
      </DatePicker>
      <div class="mt-2">
        <p class="text-grey-light text-md">{{ nrOfDays }} days</p>
      </div>
    </div>
    <div class="flex flex-row mt-4 space-x-4">
      <div>
        <div class="flex items-center">
          <p class="font-medium text-grey-light text-sm-2">
            Use For Lead Generation
          </p>
          <p class="font-medium text-blue-dark-1 text-sm-2 w-2 mr-1 ml-3">
            {{ details.lead ? 'On' : 'Off' }}
          </p>
          <Toggle :value="details.lead" :onChange="() => setFormValue({ key: 'lead', value: !details.lead })" />
        </div>
      </div>
      <div>
        <div class="flex items-center">
          <p class="font-medium text-grey-light text-sm-2">
            Access to Past Results
          </p>
          <p class="font-medium text-blue-dark-1 text-sm-2 w-2 mr-1 ml-3">
            {{ details.pastResults ? 'On' : 'Off' }}
          </p>
          <Toggle :value="details.pastResults" :onChange="() => setFormValue({ key: 'pastResults', value: !details.pastResults })" />
        </div>
      </div>
      <div>
        <div class="flex items-center">
          <p class="font-medium text-grey-light text-sm-2">
            Ability to export data in .XLS Format
          </p>
          <p class="font-medium text-blue-dark-1 text-sm-2 w-2 mr-1 ml-3">
            {{ details.exportPermission ? 'On' : 'Off' }}
          </p>
          <Toggle :value="details.exportPermission" :onChange="() => setFormValue({ key: 'exportPermission', value: !details.exportPermission })" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { mapState, mapActions } from 'vuex';
import Toggle from '@/components/Toggle/Toggle.vue';
import timezoneOptions from "../../../../../components/DateTimePicker/timezoneOptions";

Vue.component('date-picker', DatePicker);

export default {
  name: 'SubscriptionDates',
  components: {
    DatePicker,
    Toggle,
  },
  data: () => ({
    timezoneOptions,
  }),
  computed: {
    ...mapState({
      details: (state) => state.subscriptions.form,
    }),
    start: {
      get() {
        return this.details.startDate;
      },
      set(value) {
        return this.setFormValue({ key: 'startDate', value });
      },
    },
    end: {
      get() {
        return this.details.endDate;
      },
      set(value) {
        return this.setFormValue({ key: 'endDate', value });
      },
    },
    timezone: {
      get() {
        return this.details.timezone;
      },
      set(value) {
        return this.setFormValue({ key: 'timezone', value });
      }
    },
    nrOfDays() {
      let difference = new Date(this.end).getTime() - new Date(this.start).getTime();
      let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
      return totalDays;
    },
  },
  methods: {
    ...mapActions({
      setFormValue: 'subscriptions/setFormValue',
    }),
  },
};
</script>
